import { Disclosure, Menu, Switch, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "./Icon";
import { l } from "./languageUtils";
import { navigate } from "astro:transitions/client";
import EmpaveliImage from "../assets/images/empaveliLogo.png";
import EmpaveliImageFR from "../assets/images/empaveliLogoFR.png";
import { handleLangChange } from "./languageUtils";

interface NavbarProps {
  lang: "en" | "CA-fr";
  className?: string;
}

const Navbar: React.FC<NavbarProps> = ({ lang, className }) => {
  const currentLang = window.location.pathname.split("/")[1] === "fr" ? "CA-fr" : "en";
  console.log("currentLang", currentLang);

  return (
    <div className="flex flex-col w-full md:px-6">
      <div className={twMerge("flex flex-col sm:flex-row justify-between items-start pt-4 max-w-7xl", className)}>
        <img
          src={currentLang === "en" ? EmpaveliImage.src : EmpaveliImageFR.src}
          className="flex flex-col self-center h-14 mb-4"
        />
        <div className="flex flex-col self-center">
          {currentLang === "en" ? (
            <a href="/fr">
              <div className="hidden sm:flex bg-[#F5F5F5] w-20 rounded-full bg-gradient-to-b from-gray-500/25 to-gray-50/85">
                <div className="bg-white w-10 rounded-full my-0.5 ml-0.5 text-center text-xs md:text-base">EN</div>
                <div className="my-0.5 mx-auto text-center text-xs md:text-base">FR</div>
              </div>

              <div className="flex sm:hidden">
                <div className="text-[#616C83]">
                  <span className="text-[#5A4A88] font-semibold">EN</span> |{" "}
                  <span className="font-semibold underline">FR</span>
                </div>
              </div>
            </a>
          ) : (
            <a href="/">
              <div className="hidden sm:flex bg-[#F5F5F5] w-20 rounded-full bg-gradient-to-b from-gray-500/25 to-gray-50/85">
                <div className="my-0.5 mx-auto text-center text-xs md:text-base">EN</div>
                <div className="bg-white w-10 rounded-full my-0.5 mr-0.5 text-center text-xs md:text-base">FR</div>
              </div>

              <div className="flex sm:hidden">
                <div className="text-[#616C83]">
                  <span className="font-semibold underline">EN</span> |{" "}
                  <span className="text-[#5A4A88] font-semibold">FR</span>
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
